// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2-js": () => import("./../../../src/pages/2.js" /* webpackChunkName: "component---src-pages-2-js" */),
  "component---src-pages-3-js": () => import("./../../../src/pages/3.js" /* webpackChunkName: "component---src-pages-3-js" */),
  "component---src-pages-4-js": () => import("./../../../src/pages/4.js" /* webpackChunkName: "component---src-pages-4-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-6-js": () => import("./../../../src/pages/6.js" /* webpackChunkName: "component---src-pages-6-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

